<template>
  <v-bottom-navigation :value="value" color="teal" grow app>
    <v-btn :to="{ name: 'app-dashboard' }">
      <span>{{ $t("navigation.homeTitle") }}</span>

      <v-icon>mdi-home</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'campaigns' }">
      <span>{{ $t("navigation.campaignsTitle") }}</span>

      <v-icon>mdi-cellphone-wireless</v-icon>
    </v-btn>

    <v-btn :to="{ name: 'rewards' }">
      <span>{{ $t("navigation.rewardsTitle") }}</span>

      <v-icon>mdi-gift</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>
<script>
export default {
  data: () => ({ value: 0 })
};
</script>
